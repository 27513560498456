import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';

const HUBSROUTE = '/signalr-hubs/';

export abstract class BaseSignalR {
  protected apiUrl: string;
  private _hubConnection: signalR.HubConnection | undefined;
  private _hubConnectionEstablished$ = new BehaviorSubject<boolean>(false);

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  public get hubConnection(): signalR.HubConnection {
    return this._hubConnection;
  }

  public get hubConnectionEstablished$(): BehaviorSubject<boolean> {
    return this._hubConnectionEstablished$;
  }

  /**
   * Build the SignalR connection using provided parameters
   * @param {string} hubName - The name of the hub
   * @param {string} accessToken - The access token of the logged in user
   */
  public buildConnection(hubName: string, accessToken: string): void {
    const hubUrl = `${this.apiUrl}${HUBSROUTE}${hubName}`;

    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => accessToken,
      })
      //default = [0, 2000, 10000, 30000] 0, 2, 10 and 30 seconds exponential backoff
      .withAutomaticReconnect()
      .build();
  }

  /**
   * Start the SignalR connection
   */
  startConnection = async () => {
    try {
      await this._hubConnection.start();
      this._hubConnectionEstablished$.next(true);
    } catch (err) {
      this._hubConnectionEstablished$.next(false);
    }
  };

  /**
   * Stop the SignalR connection
   */
  public stopConnection(): void {
    if (this._hubConnection) {
      this._hubConnection.stop();
      this._hubConnection = null;
    }
  }

  /**
   * Initialize events that will be received from the hub on the SignalR connection
   */
  protected abstract initEvents(): void;
}
